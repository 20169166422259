/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": process.env.API_REGION,
    "aws_appsync_graphqlEndpoint": process.env.GRAPHQL_URL,
    "aws_appsync_region": process.env.API_REGION,
    "aws_appsync_authenticationType": "AWS_LAMBDA",
    ssr: true,
  };


export default awsmobile;
